<template>
<Page id="statistikcs" title="Statistik">
    <Loader v-if="loading"></Loader>
    <Alert v-else-if="!hasstats" variant="info" >Det finns just nu ingen statistik att visa</Alert>
    <div v-else>
        <div class="summary">
            <Alert variant="info">
                Turnerningen {{tournament.name}} startade {{tournament.startdate}} och det har spelats {{gamestats.played}} av {{gamestats.total}} matcher. Det har under ordinarie tid gjorts {{gamestats.goals}} st mål. I turneringen {{allplayed? "deltog": "deltar"}} {{userstats.total}} användare varav {{userstats.paying}} betalande.
            </Alert>
        </div>
        <div class="tipsligan py-2">
            <div class="tips-stats py-2">
                <h3 class="text-center">Topplaceringar</h3>
                <b-row>
                    <b-col v-for="(leader, index) in leaders" :key="index" cols="12" md="6" lg="4" class="d-flex w-100">
                        <div class="card w-100">
                            <div class="text-center">
                                <h4>{{leader.title}}</h4>
                                <h2>{{leader.value}}</h2>
                            </div>
                            <div class="text-center d-flex align-items-center justify-content-center ">
                                <div v-for="user in leader.users" :key="user.userid" class="groups-team mx-1 py-1 px-3" :id="'statistics_leaders_user_row_' + index+ '_' + user.userid">
                                    <b-img rounded="circle" class="tips-user-image" :src="avatar(user)" /><br>{{ user.username }}
                                    <UserPopover :target="'statistics_leaders_user_row_' + index + '_' + user.userid" :user="user"></UserPopover>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <hr class="mt-5 pt-5" />
            <div class="tips-leaders py-2">
                <h3 class="text-center">Ledare</h3>
                <p class="font-italic text-center">Följande användare har någon gång under tipset varit i ledning eller i delad ledning</p>
                <div class="tips-stats">
                    <b-row class="table-header">
                        <b-col>
                            Användare
                        </b-col>
                        <b-col class="text-right">
                            Omgångar
                        </b-col>
                        <b-col class="text-right">
                            {{allplayed? "Slutplacering": "Nuvarande placering"}}
                        </b-col>
                    </b-row>
                    <b-row class="stats-row" v-for="item in topplacements" :key="item.userid">
                        <b-col class="groups-team" :id="'statistics_leaders_user_row_' + item.userid">
                            <b-img rounded="circle" class="tips-user-image" :src="avatar(item)" />{{ item.username }}
                            <UserPopover :target="'statistics_leaders_user_row_' + item.userid" :user="item"></UserPopover>
                        </b-col>
                        <b-col class="text-right">{{item.value}}</b-col>
                        <b-col class="text-right">{{userplacement(item.userid)}}</b-col>
                    </b-row>
                </div>
            </div>
        </div>
        <hr class="mt-5 pt-5" />
        <div>
            <h3 class="text-center">Lagstatistik</h3>
             <p class="font-italic text-center">Nedan följer en sammanställning av lagens prestation under ordinarie tid!</p>
            <div class="tips-stats">
               <b-row class="table-header">
                    <b-col>Namn</b-col>
                    <b-col class="text-right">Matcher</b-col>
                    <b-col class="text-right">Vinster</b-col>
                    <b-col class="text-right">Mål</b-col>
                </b-row>
                <b-row class="stats-row" v-for="(team, index) in teamstats" :key="index">
                    <b-col>
                        <Team small :team="getTeam(team.teamid)"></Team>
                    </b-col>
                    <b-col class="text-right">
                        {{team.games}}
                    </b-col>
                    <b-col class="text-right">
                        {{team.win}}
                    </b-col>
                    <b-col class="text-right">
                        {{team.goalsf}} - {{team.goalsa}}
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</Page>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Page = () => import("@/components/common/Page");
const Loader = () => import("@/components/miscellaneous/Loader");
const Team = () => import("@/components/team/Team");

export default {
    name: "Statistik",
    components: {
        Alert,
        Page,
        Loader,
        Team
    },
    data() {
        return {};
    },
    metaInfo: {
        title: "Statistik",
        meta: [{
            name: "description",
            content: "Här samlar vi lite rolig information om turneringen",
        }, ],
    },
    computed: {
        loading() {
            return this.$store.getters["tournamentsg/tournamentloading"] || this.$store.getters["tournamentsg/tournamentstatsloading"] || this.$store.getters["tournamentsg/tournamentstatsloading"] || this.$store.getters["teams/teamsloading"];
        },
        hasstats() {
            var s = this.stats;
            if (s && s.games)
                return true;
            return false;
        },
        gamestats() {
            return this.stats.games;
        },
        leaders() {
            var leaders = [];
            var total = this.stats.leaders.totalpoints;
            total.title = "Totalpoäng";
            leaders.push(total);

            var results = this.stats.leaders.resultpoints;
            results.title = "Resultat";
            leaders.push(results);

            var bets = this.stats.leaders.betpoints;
            bets.title = "1-X-2";
            leaders.push(bets);

            var bonuspoints = this.stats.leaders.bonuspoints;
            bonuspoints.title = "Bonus";
            leaders.push(bonuspoints);

            var questions = this.stats.leaders.questionpoints;
            questions.title = "Frågor";
            leaders.push(questions);

            var snitt = this.stats.leaders.gamesnitt;
            snitt.title = "Snitt";
            leaders.push(snitt);

            return leaders;
        },
        allplayed(){
            return this.gamestats["played"] == this.gamestats["total"];
        },
        stats() {
            return this.$store.getters["tournamentsg/tournamentstats"];
        },
        teamstats() {
            var s = this.stats;
            return this.$functions.sortint(s.teams, "win", true);
        },
        topplacements() {
            var s = this.stats.leaders.topplacement;
            return this.$functions.sortint(s, "value", true);
        },
        userstats(){
            return this.stats.users;
        },
        usersaccess() {
            var data = this.$store.getters["stats/stats"];
            if (data)
                return data.useraccess;
            return {};
        },
        tournament() {
            return this.$store.getters["tournamentsg/tournament"];
        },
        tipsstats() {
            var data = this.$store.getters["stats/stats"];
            if(!data)
                return [];
            var stats = data.stats.filter((row) => {
                var ua = data.useraccess[row.userid];
                if (ua && ua >= 20) {
                    return true;
                }
                return false;
            });

            var placements = this.$functions.fixPlacements(
                stats,
                "totalpoints",
                "placement"
            );
            return placements;
        }
    },
    methods: {
        avatar(row) {
            return this.$images.userAvatar(row.userid);
        },
        round(number, decimals) {
            return this.$formatter.round(number, decimals);
        },
        userAccess(uid) {
            var allaccess = this.usersaccess;
            if (allaccess[uid])
                return allaccess[uid];
            return false;
        },
        getTeam(id) {
            return this.$functions.getTeam(id);
        },
        getStats() {
            this.$store.dispatch("tournamentsg/getTournamentStats");
        },
        userplacement(userid) {
            var allrows = this.tipsstats;
            for (var i = 0; i < allrows.length; i++)
                if (allrows[i].userid == userid)
                    return allrows[i].placement;

            return "";
        }
    },
    mounted() {
        this.getStats();
    }
};
</script>
